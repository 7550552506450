import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="articles-ctn">
        {posts &&
          posts.map(({ node: post }) => (
              <article className="article-box" key={post.id}>
                <div>
                  <div className="img-wrap">
                    <div className="article-img"
                    style={{
                      backgroundImage: `url(${
                          !!post.frontmatter.image.childImageSharp ? post.frontmatter.image.childImageSharp.fluid.src : post.frontmatter.image
                          })`
                    }}></div>
                  </div>
                  <div className="article-head">
                    <Link
                        className="title article has-text-primary is-size-4"
                        to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    <span className="subtitle is-block">
                      {post.frontmatter.date}
                    </span>
                  </div>
                  <div className="article-description">
                    {post.frontmatter.description}
                  </div>
                </div>
                <div className="article-actions">
                  <Link className="btn" to={post.fields.slug}>
                    En savoir plus
                  </Link>
                </div>
              </article>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "DD MMMM YYYY")
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 1680, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
